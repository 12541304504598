
import { Component, Vue } from "vue-property-decorator";
import * as StatServices from "@/services/StatServices";

@Component
export default class Claims extends Vue {

  stats = {
    articlesPerCategory: [] as any[],
    articlesPerClaimant: [] as any[],
  };

  headers = [
    { value: "claimant", text: "Claimant" },
    { value: "count", text: "# Facthecks" },
  ];

  loading = {
    chart: true,
  }

  percentageFormatter(value: any) {
    return `${value} %`
  }

  search = '';

  chartData = {
    claimsPerCategory: {
      series: [] as any[]
    }
  }

  chartOptions = {
    claimsPerCategory: {
      chart: {
        type: 'treemap',
        toolbar: {
          show: true
        },
      },
      labels: [] as string[],
    }
  }

  async mounted() {
    this.loading.chart = true;

    this.stats.articlesPerCategory =
      await StatServices.GetFactcheckCountsPerCategory();

    this.chartData.claimsPerCategory = {
      series: [
        {
          data: this.stats.articlesPerCategory.map(e => {
            return {
              y: e.claimCount,
              x: e.nl
            }
          }).sort((a, b) => b.y - a.y)
        }
      ]
    }

    this.chartOptions.claimsPerCategory = {
      ...this.chartOptions.claimsPerCategory,
      labels: this.stats.articlesPerCategory.map(e => e.nl)
    }

    this.loading.chart = false;
    this.stats.articlesPerClaimant =
      await StatServices.GetFactcheckCountsPerClaimant();
  }
}
